<template>
  <div class="analyst-container">
    <h2 class="text-2xl font-bold mb-4" v-if="edit == false">
      Ajout d'un analyste
    </h2>
    <h2 class="text-2xl font-bold mb-4" v-if="edit == true">
      Modification d'un analyste
    </h2>

    <label for="firstname">Prénom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="analyst.firstname"
      type="text"
      name="firstname"
      id="firstname"
    />
    <br />

    <label for="lastname">Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="analyst.lastname"
      type="text"
      name="lastname"
      id="lastname"
    />
    <br />

    <label for="email">Email :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="analyst.email"
      type="email"
      name="email"
      id="email"
    />
    <br />

    <label for="enabled">Activé :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="analyst.enabled"
      type="checkbox"
      name="enabled"
      id="enabled"
    />
    <br />

    <label for="crmId">ID CRM :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="analyst.crmId"
      type="number"
      name="crmId"
      id="crmId"
    />
    <br />

    <button
      class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
      v-if="edit == false"
      @click="addAnalyst(analyst)"
    >
      Créer
    </button>
    <button
      class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
      v-if="edit == true"
      @click="updateAnalyst(analyst)"
    >
      Modifier
    </button>
  </div>
</template>

<script>
import router from "../../router";

import AnalystService from "../../services/analyst.service";

export default {
  name: "AnalystAdd",
  data: () => {
    return {
      analyst: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        enabled: true,
        crmId: null,
      },
      edit: null,
      error: null,
      title: " un analyste - Répartiteur PROJEFI",
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  mounted() {
    if (this.$route.path == "/analyst/add") {
      this.edit = false;
      this.title = "Ajouter" + this.title;
    } else {
      this.edit = true;
      this.title = "Modifier" + this.title;
      this.analyst.id = this.$route.params.id;

      AnalystService.getAnalyst(this.analyst.id).then(
        (response) => {
          if (response.data.message) {
            alert(response.data.message);
            return;
          } else {
            this.analyst.firstname = response.data.firstname;
            this.analyst.lastname = response.data.lastname;
            this.analyst.email = response.data.email;
            this.analyst.enabled = response.data.enabled;
            this.analyst.crmId = response.data.crmId;
          }
        },
        (error) => {
          this.error =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  methods: {
    addAnalyst: (analyst) => {
      if (
        analyst.firstname == null ||
        analyst.firstname == "" ||
        analyst.lastname == null ||
        analyst.lastname == "" ||
        analyst.email == null ||
        analyst.email == "" ||
        analyst.crmId == null ||
        analyst.crmId === ""
      ) {
        alert("Aucun champ ne doit être laissé vide.");
        return;
      }

      analyst.lastname = analyst.lastname.toUpperCase();

      AnalystService.postAnalyst(analyst).then((response) => {
        alert(response.data.message);
        router.push("/analyst/list");
      });
    },
    updateAnalyst: (analyst) => {
      if (confirm("Êtes-vous sûr de vouloir modifier l'analyste ?")) {
        if (
          analyst.firstname == null ||
          analyst.firstname == "" ||
          analyst.lastname == null ||
          analyst.lastname == "" ||
          analyst.email == null ||
          analyst.email == "" ||
          analyst.crmId == null ||
          analyst.crmId === ""
        ) {
          alert("Aucun champ ne doit être laissé vide.");
          return;
        }

        analyst.lastname = analyst.lastname.toUpperCase();

        AnalystService.putAnalyst(analyst).then((response) => {
          alert(response.data.message);
          router.push("/analyst/list");
        });
      }
    },
  },
};
</script>

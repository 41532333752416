import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class StatisticService {
  getCountSlidingYearByHosting() {
    return axios.get(apiUri + "/statistic/slidingYearByHosting", {
      headers: authHeader(),
    });
  }

  getCountByHosting(request) {
    return axios.post(
      apiUri + "/statistic/byHosting",
      {
        dayMin: request.dayMin,
        dayMax: request.dayMax,
        interval: request.interval,
        analysts: request.analysts,
        departments: request.departments,
      },
      {
        headers: authHeader(),
      }
    );
  }

  getCountBySource(request) {
    return axios.post(
      apiUri + "/statistic/bySource",
      {
        dayMin: request.dayMin,
        dayMax: request.dayMax,
        interval: request.interval,
        analysts: request.analysts,
        departments: request.departments,
      },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new StatisticService();

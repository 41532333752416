<template>
  <div>
    <h2 class="text-2xl font-bold mb-4">Se connecter</h2>

    <table>
      <tr>
        <td><label for="email">Email :</label></td>
        <td>
          <input
            class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
            type="email"
            name="email"
            id="email"
            v-validate="'required'"
            v-model="user.email"
          />
        </td>
      </tr>
      <tr>
        <td><label for="password">Mot de passe :</label></td>
        <td>
          <input
            class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
            type="password"
            name="password"
            id="password"
            v-model="user.password"
            v-validate="'required'"
          />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <button
            class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded w-full"
            @click="handleLogin()"
          >
            Connexion
          </button>
        </td>
      </tr>
    </table>

    <p
      v-if="error.message"
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200"
    >
      {{ error.message }}
    </p>
    <br v-if="error.message" />
  </div>
</template>

<script>
import User from "../models/user";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      error: "",
    };
  },
  metaInfo() {
    return {
      title: "Se connecter - Répartiteur PROJEFI",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.email && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/");
            },
            (error) => {
              this.loading = false;
              this.error =
                (error.response && error.response.data) ||
                error.error ||
                error.toString();
            }
          );
        }
      });
    },
  },
};
</script>

import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class TypeOfHostingService {
  getTypeOfHostings() {
    return axios.get(apiUri + "/typeOfHosting", { headers: authHeader() });
  }

  getTypeOfHosting(typeOfHostingId) {
    return axios.get(apiUri + "/typeOfHosting/" + typeOfHostingId, {
      headers: authHeader(),
    });
  }

  postTypeOfHosting(typeOfHosting) {
    return axios.post(
      apiUri + "/typeOfHosting",
      {
        name: typeOfHosting.name,
      },
      { headers: authHeader() }
    );
  }

  putTypeOfHosting(typeOfHosting) {
    return axios.put(
      apiUri + "/typeOfHosting",
      {
        id: typeOfHosting.id,
        name: typeOfHosting.name,
      },
      { headers: authHeader() }
    );
  }

  deleteTypeOfHosting(typeOfHostingId) {
    return axios.delete(apiUri + "/typeOfHosting/" + typeOfHostingId, {
      headers: authHeader(),
    });
  }
}

export default new TypeOfHostingService();

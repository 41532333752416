<template>
  <div id="analyst">
    <router-view />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faEdit,
  faTrash,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

library.add(faPlus);
library.add(faEdit);
library.add(faTrash);
library.add(faCheck);
library.add(faTimes);

export default {
  name: "Analyst",
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Form from "../views/Form.vue";
import FormList from "../components/form/FormList.vue";
import FormAdd from "../components/form/FormAdd.vue";
import Agency from "../views/Form.vue";
import AgencyList from "../components/agency/AgencyList.vue";
import AgencyAdd from "../components/agency/AgencyAdd.vue";
import Analyst from "../views/Analyst.vue";
import AnalystList from "../components/analyst/AnalystList.vue";
import AnalystAdd from "../components/analyst/AnalystAdd.vue";
import Profile from "../views/Profile.vue";
import ProfileList from "../components/profile/ProfileList.vue";
import ProfileAdd from "../components/profile/ProfileAdd.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/form",
    name: "Form",
    component: Form,
    redirect: "/form/list",
    children: [
      {
        path: "list",
        name: "FormList",
        component: FormList,
      },
      {
        path: "add",
        name: "FormAdd",
        component: FormAdd,
      },
      {
        path: "edit/:id",
        name: "FormEdit",
        component: FormAdd,
      },
    ],
  },
  {
    path: "/agency",
    name: "Agency",
    component: Agency,
    redirect: "/agency/list",
    children: [
      {
        path: "list",
        name: "AgencyList",
        component: AgencyList,
      },
      {
        path: "add",
        name: "AgencyAdd",
        component: AgencyAdd,
      },
      {
        path: "edit/:id",
        name: "AgencyEdit",
        component: AgencyAdd,
      },
    ],
  },
  {
    path: "/analyst",
    name: "Analyst",
    component: Analyst,
    redirect: "/analyst/list",
    children: [
      {
        path: "list",
        name: "AnalystList",
        component: AnalystList,
      },
      {
        path: "add",
        name: "AnalystAdd",
        component: AnalystAdd,
      },
      {
        path: "edit/:id",
        name: "AnalystEdit",
        component: AnalystAdd,
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    redirect: "/profile/list",
    children: [
      {
        path: "list",
        name: "ProfileList",
        component: ProfileList,
      },
      {
        path: "add",
        name: "ProfileAdd",
        component: ProfileAdd,
      },
      {
        path: "edit/:id",
        name: "ProfileEdit",
        component: ProfileAdd,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;

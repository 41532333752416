<template>
  <div class="analyst-container">
    <h2 class="text-2xl font-bold mb-4">
      Analystes
      <button
        class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
        @click="addAnalyst()"
      >
        <font-awesome-icon icon="plus" />
      </button>
    </h2>

    <p
      v-if="error"
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200 mb-4"
    >
      {{ error.message }}
    </p>

    <table v-if="analysts.length > 0" class="table-fixed w-full text-center">
      <thead>
        <tr>
          <!-- <th class="w-1/8 bg-gray-100 border px-2 py-1">ID</th> -->
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Nom</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Prénom</th>
          <th class="w-2/8 bg-gray-100 border px-2 py-1">Email</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Activé</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">ID CRM</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Modifier</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Supprimer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="analyst in analysts" :key="analyst._id">
          <!-- <td class="border px-2 py-1">{{ analyst._id }}</td> -->
          <td class="border px-2 py-1">{{ analyst.lastname }}</td>
          <td class="border px-2 py-1">{{ analyst.firstname }}</td>
          <td class="border px-2 py-1">{{ analyst.email }}</td>
          <td class="border px-2 py-1">
            <!-- {{ analyst.enabled }} -->
            <font-awesome-icon
              v-if="analyst.enabled == true"
              icon="check"
              class="text-green-500"
            /><font-awesome-icon
              v-if="analyst.enabled == false"
              icon="times"
              class="text-red-500"
            />
          </td>
          <td class="border px-2 py-1">{{ analyst.crmId }}</td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
              @click="updateAnalyst(analyst._id)"
            >
              <font-awesome-icon icon="edit" />
            </button>
          </td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-red-500 hover:bg-red-700 text-white px-2 py-1 rounded"
              @click="deleteAnalyst(analyst)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <p
      v-else
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200"
    >
      Aucun analyste n'a été retrouvé.
    </p>
  </div>
</template>

<script>
import router from "../../router";

import AnalystService from "../../services/analyst.service";

export default {
  name: "AnalystList",
  data: () => {
    return {
      analysts: null,
      error: null,
    };
  },
  metaInfo() {
    return {
      title: "Analystes - Répartiteur PROJEFI",
    };
  },
  mounted() {
    AnalystService.getAnalysts().then(
      (response) => {
        this.analysts = response.data;
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    addAnalyst: () => {
      router.push("/analyst/add");
    },
    updateAnalyst: (analystId) => {
      router.push("/analyst/edit/" + analystId);
    },
    deleteAnalyst: (analyst) => {
      if (
        confirm(
          "Êtes-vous sûr de vouloir supprimer l'analyste " +
            analyst.firstname +
            " " +
            analyst.lastname +
            " ?"
        )
      ) {
        AnalystService.deleteAnalyst(analyst._id).then(
          (response) => {
            alert(response.data.message);
            document.location.reload();
          },
          (error) => {
            this.error =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class AgencyService {
  getAgencies() {
    return axios.get(apiUri + "/agency", { headers: authHeader() });
  }

  getAgency(agencyId) {
    return axios.get(apiUri + "/agency/" + agencyId, { headers: authHeader() });
  }

  postAgency(agency) {
    return axios.post(
      apiUri + "/agency",
      { name: agency.name, analysts: agency.analysts },
      { headers: authHeader() }
    );
  }

  putAgency(agency) {
    return axios.put(
      apiUri + "/agency",
      {
        id: agency.id,
        name: agency.name,
        analysts: agency.analysts,
      },
      { headers: authHeader() }
    );
  }

  deleteAgency(agencyId) {
    return axios.delete(apiUri + "/agency/" + agencyId, {
      headers: authHeader(),
    });
  }
}

export default new AgencyService();

<template>
  <div class="profile-container">
    <h2 class="text-2xl font-bold mb-4" v-if="edit == false">
      Ajout d'un profile
    </h2>
    <h2 class="text-2xl font-bold mb-4" v-if="edit == true">
      Modification d'un profile
    </h2>

    <label for="name">Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.name"
      type="text"
      name="name"
      id="name"
    />
    <br />

    <label for="bankOfFranceFile">Fiché banque de France :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.bankOfFranceFile"
      type="checkbox"
      name="bankOfFranceFile"
      id="bankOfFranceFile"
    />
    <br />

    <label for="typeOfRequest">Type de demande :</label>
    <!-- <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.typeOfRequest"
      type="text"
      name="typeOfRequest"
      id="typeOfRequest"
    /> -->
    <select
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      name="typeOfRequest"
      id="typeOfRequest"
      v-model="profile.typeOfRequest"
    >
      <option
        v-for="typeOfRequest in typesOfRequest"
        v-bind:key="typeOfRequest"
        v-bind:value="typeOfRequest"
        >{{ typeOfRequest }}</option
      >
    </select>
    <br />

    <!-- <label for="counter">Compteur :</label> -->
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.counter"
      type="hidden"
      name="counter"
      id="counter"
    />
    <!-- <br /> -->

    <label for="departments">Départements :</label>
    <ul style="column-count:4;">
      <li v-for="department in departments" :key="department._id">
        <input
          class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
          v-model="profile.departments"
          type="checkbox"
          :name="department._id"
          :id="department._id"
          :value="department._id"
        />
        {{ department.number }} - {{ department.name }}
      </li>
    </ul>
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white px-2 py-1 rounded mx-px"
      @click="selectAllDepartments()"
    >
      Tout sélectionner
    </button>
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white px-2 py-1 rounded mx-px"
      @click="deselectAllDepartments()"
    >
      Tout désélectionner
    </button>
    <br /><br />

    <!-- <label for="amountOfConsumerLoans.name">Prêts conso - Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfConsumerLoans.name"
      type="text"
      name="amountOfConsumerLoans.name"
      id="amountOfConsumerLoans.name"
    />
    <br /> -->

    <label for="amountOfConsumerLoans.amountMin"
      >Prêts conso - Montant min :</label
    >
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfConsumerLoans.amountMin"
      type="number"
      name="amountOfConsumerLoans.amountMin"
      id="amountOfConsumerLoans.amountMin"
    />
    <br />

    <label for="amountOfConsumerLoans.amountMax"
      >Prêts conso - Montant max :</label
    >
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfConsumerLoans.amountMax"
      type="number"
      name="amountOfConsumerLoans.amountMax"
      id="amountOfConsumerLoans.amountMax"
    />
    <br />

    <!-- <label for="amountOfPropertyLoans.name">Prêts immo - Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfPropertyLoans.name"
      type="text"
      name="amountOfPropertyLoans.name"
      id="amountOfPropertyLoans.name"
    />
    <br /> -->

    <label for="amountOfPropertyLoans.amountMin"
      >Prêts immo - Montant min :</label
    >
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfPropertyLoans.amountMin"
      type="number"
      name="amountOfPropertyLoans.amountMin"
      id="amountOfPropertyLoans.amountMin"
    />
    <br />

    <label for="amountOfPropertyLoans.amountMax"
      >Prêts immo - Montant max :</label
    >
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfPropertyLoans.amountMax"
      type="number"
      name="amountOfPropertyLoans.amountMax"
      id="amountOfPropertyLoans.amountMax"
    />
    <br />

    <!-- <label for="amountOfNeed.name">Besoin - Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfNeed.name"
      type="text"
      name="amountOfNeed.name"
      id="amountOfNeed.name"
    />
    <br /> -->

    <label for="amountOfNeed.amountMin">Besoin - Montant min :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfNeed.amountMin"
      type="number"
      name="amountOfNeed.amountMin"
      id="amountOfNeed.amountMin"
    />
    <br />

    <label for="amountOfNeed.amountMax">Besoin - Montant max :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.amountOfNeed.amountMax"
      type="number"
      name="amountOfNeed.amountMax"
      id="amountOfNeed.amountMax"
    />
    <br />

    <label for="typesOfHosting">Types d'hébergement :</label>
    <ul>
      <li v-for="typeOfHosting in typesOfHosting" :key="typeOfHosting._id">
        <input
          class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
          v-model="profile.typesOfHosting"
          type="checkbox"
          :name="typeOfHosting._id"
          :id="typeOfHosting._id"
          :value="typeOfHosting._id"
        />
        {{ typeOfHosting.name }}
      </li>
    </ul>
    <br />

    <label for="primaryAnalysts">Analystes primaires :</label>
    <ul>
      <li v-for="analyst in analysts" :key="analyst._id">
        <input
          class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
          v-model="profile.primaryAnalysts"
          type="checkbox"
          :name="analyst._id"
          :id="analyst._id"
          :value="analyst._id"
        />
        {{ analyst.crmId }} - {{ analyst.firstname }} {{ analyst.lastname }}
      </li>
    </ul>
    <br />

    <label for="secondaryAnalysts">Analystes secondaires :</label>
    <ul>
      <li v-for="analyst in analysts" :key="analyst._id">
        <input
          class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
          v-model="profile.secondaryAnalysts"
          type="checkbox"
          :name="analyst._id"
          :id="analyst._id"
          :value="analyst._id"
        />
        {{ analyst.crmId }} - {{ analyst.firstname }} {{ analyst.lastname }}
      </li>
    </ul>
    <br />

    <!-- <label for="crmId">ID CRM :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="profile.crmId"
      type="number"
      name="crmId"
      id="crmId"
    />
    <br /> -->

    <button
      class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
      v-if="edit == false"
      @click="addProfile(profile)"
    >
      Créer
    </button>
    <button
      class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
      v-if="edit == true"
      @click="updateProfile(profile)"
    >
      Modifier
    </button>
  </div>
</template>

<script>
import router from "../../router";

import ProfileService from "../../services/profile.service";
import DepartmentService from "../../services/department.service";
import TypeOfHostingService from "../../services/typeOfHosting.service";
import AnalystService from "../../services/analyst.service";
import TypeOfAmountService from "../../services/typeOfAmount.service";

export default {
  name: "ProfileAdd",
  data: () => {
    return {
      profile: {
        id: null,
        name: null,
        bankOfFranceFile: false,
        typeOfRequest: null,
        counter: null,
        departments: [],
        amountOfConsumerLoans: {
          id: null,
          name: null,
          amountMin: null,
          amountMax: null,
        },
        amountOfPropertyLoans: {
          id: null,
          name: null,
          amountMin: null,
          amountMax: null,
        },
        amountOfNeed: {
          id: null,
          name: null,
          amountMin: null,
          amountMax: null,
        },
        typesOfHosting: [],
        primaryAnalysts: [],
        secondaryAnalysts: [],
      },
      typesOfRequest: ["RAC", "HYPO", "PERSO", "AUTO", "IMMO", "TRAVAUX"],
      departments: null,
      typesOfHosting: null,
      analysts: null,
      edit: null,
      error: null,
      title: " un profile - Répartiteur PROJEFI",
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  mounted() {
    if (this.$route.path == "/profile/add") {
      this.edit = false;
      this.title = "Ajouter" + this.title;
    } else {
      this.edit = true;
      this.title = "Modifier" + this.title;
      this.profile.id = this.$route.params.id;

      ProfileService.getProfile(this.profile.id).then(
        (response) => {
          if (response.data.message) {
            alert(response.data.message);
            return;
          } else {
            this.profile.name = response.data.name;
            this.profile.bankOfFranceFile = response.data.bankOfFranceFile;
            this.profile.typeOfRequest = response.data.typeOfRequest;
            this.profile.counter = response.data.counter;
            this.profile.departments = response.data.departments;
            this.profile.amountOfConsumerLoans.id =
              response.data.amountOfConsumerLoans;
            this.profile.amountOfPropertyLoans.id =
              response.data.amountOfPropertyLoans;
            this.profile.amountOfNeed.id = response.data.amountOfNeed;
            this.profile.typesOfHosting = response.data.typesOfHosting;
            this.profile.primaryAnalysts = response.data.primaryAnalysts;
            this.profile.secondaryAnalysts = response.data.secondaryAnalysts;

            TypeOfAmountService.getTypeOfAmount(
              this.profile.amountOfConsumerLoans.id
            ).then(
              (response) => {
                if (response.data.message) {
                  alert(response.data.message);
                  return;
                } else {
                  this.profile.amountOfConsumerLoans.name = response.data.name;
                  this.profile.amountOfConsumerLoans.amountMin =
                    response.data.amountMin;
                  this.profile.amountOfConsumerLoans.amountMax =
                    response.data.amountMax;
                }
              },
              (error) => {
                this.error =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              }
            );

            TypeOfAmountService.getTypeOfAmount(
              this.profile.amountOfPropertyLoans.id
            ).then(
              (response) => {
                if (response.data.message) {
                  alert(response.data.message);
                  return;
                } else {
                  this.profile.amountOfPropertyLoans.name = response.data.name;
                  this.profile.amountOfPropertyLoans.amountMin =
                    response.data.amountMin;
                  this.profile.amountOfPropertyLoans.amountMax =
                    response.data.amountMax;
                }
              },
              (error) => {
                this.error =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              }
            );

            TypeOfAmountService.getTypeOfAmount(
              this.profile.amountOfNeed.id
            ).then(
              (response) => {
                if (response.data.message) {
                  alert(response.data.message);
                  return;
                } else {
                  this.profile.amountOfNeed.name = response.data.name;
                  this.profile.amountOfNeed.amountMin = response.data.amountMin;
                  this.profile.amountOfNeed.amountMax = response.data.amountMax;
                }
              },
              (error) => {
                this.error =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              }
            );
          }
        },
        (error) => {
          this.error =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }

    DepartmentService.getDepartments().then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
          return;
        } else {
          this.departments = response.data;
        }
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );

    TypeOfHostingService.getTypeOfHostings().then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
          return;
        } else {
          this.typesOfHosting = response.data;
        }
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );

    AnalystService.getAnalysts().then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
          return;
        } else {
          this.analysts = response.data;
        }
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    selectAllDepartments: function() {
      this.profile.departments = [];

      this.departments.forEach((department) => {
        this.profile.departments.push(department._id);
      });
    },
    deselectAllDepartments: function() {
      this.profile.departments = [];
    },
    addProfile: (profile) => {
      if (
        profile.name == null ||
        profile.name == "" ||
        profile.typeOfRequest == null ||
        profile.typeOfRequest == "" ||
        profile.departments == null ||
        profile.departments == "" ||
        profile.amountOfConsumerLoans.amountMin == null ||
        profile.amountOfConsumerLoans.amountMin === "" ||
        profile.amountOfConsumerLoans.amountMax == null ||
        profile.amountOfConsumerLoans.amountMax === "" ||
        profile.amountOfPropertyLoans.amountMin == null ||
        profile.amountOfPropertyLoans.amountMin === "" ||
        profile.amountOfPropertyLoans.amountMax == null ||
        profile.amountOfPropertyLoans.amountMax === "" ||
        profile.amountOfNeed.amountMin == null ||
        profile.amountOfNeed.amountMin === "" ||
        profile.amountOfNeed.amountMax == null ||
        profile.amountOfNeed.amountMax === "" ||
        profile.typesOfHosting == null ||
        profile.typesOfHosting == "" ||
        profile.primaryAnalysts == null ||
        profile.primaryAnalysts == ""
      ) {
        alert("Aucun champ ne doit être laissé vide.");
        return;
      }

      if (profile.counter == null || profile.counter === "") {
        profile.counter = 0;
      }

      profile.amountOfConsumerLoans.name = profile.name + "-conso";
      profile.amountOfPropertyLoans.name = profile.name + "-immo";
      profile.amountOfNeed.name = profile.name + "-besoin";

      TypeOfAmountService.postTypeOfAmount(profile.amountOfConsumerLoans).then(
        (response) => {
          profile.amountOfConsumerLoans.id = response.data.id;

          TypeOfAmountService.postTypeOfAmount(
            profile.amountOfPropertyLoans
          ).then((response) => {
            profile.amountOfPropertyLoans.id = response.data.id;

            TypeOfAmountService.postTypeOfAmount(profile.amountOfNeed).then(
              (response) => {
                profile.amountOfNeed.id = response.data.id;

                ProfileService.postProfile(profile).then((response) => {
                  alert(response.data.message);
                  router.push("/profile/list");
                });
              }
            );
          });
        }
      );
    },
    updateProfile: (profile) => {
      if (confirm("Êtes-vous sûr de vouloir modifier le profile ?")) {
        if (
          profile.name == null ||
          profile.name == "" ||
          profile.typeOfRequest == null ||
          profile.typeOfRequest == "" ||
          profile.counter == null ||
          profile.counter === "" ||
          profile.departments == null ||
          profile.departments == "" ||
          profile.amountOfConsumerLoans.amountMin == null ||
          profile.amountOfConsumerLoans.amountMin === "" ||
          profile.amountOfConsumerLoans.amountMax == null ||
          profile.amountOfConsumerLoans.amountMax === "" ||
          profile.amountOfPropertyLoans.amountMin == null ||
          profile.amountOfPropertyLoans.amountMin === "" ||
          profile.amountOfPropertyLoans.amountMax == null ||
          profile.amountOfPropertyLoans.amountMax === "" ||
          profile.amountOfNeed.amountMin == null ||
          profile.amountOfNeed.amountMin === "" ||
          profile.amountOfNeed.amountMax == null ||
          profile.amountOfNeed.amountMax === "" ||
          profile.typesOfHosting == null ||
          profile.typesOfHosting == "" ||
          profile.primaryAnalysts == null ||
          profile.primaryAnalysts == ""
        ) {
          alert("Aucun champ ne doit être laissé vide." + profile.counter);
          return;
        }

        profile.amountOfConsumerLoans.name = profile.name + "-conso";
        profile.amountOfPropertyLoans.name = profile.name + "-immo";
        profile.amountOfNeed.name = profile.name + "-besoin";

        TypeOfAmountService.putTypeOfAmount(profile.amountOfConsumerLoans).then(
          (response) => {
            TypeOfAmountService.putTypeOfAmount(
              profile.amountOfPropertyLoans
            ).then((response) => {
              TypeOfAmountService.putTypeOfAmount(profile.amountOfNeed).then(
                (response) => {
                  ProfileService.putProfile(profile).then((response) => {
                    alert(response.data.message);
                    router.push("/profile/list");
                  });
                }
              );
            });
          }
        );
      }
    },
  },
};
</script>

<template>
  <div class="hosting-chart-container">
    <h2 class="text-2xl font-bold mb-4">
      Statistiques par type d'hébergement
    </h2>
    <bar-chart :chart-data="chartData" :options="options" />
    <h3 class="text-xl font-bold mt-4 mb-4">Filtres</h3>
    <form>
      <label for="dayMin">Date de début : </label>
      <input
        v-on:change="fillData()"
        class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
        type="date"
        name="dayMin"
        id="dayMin"
        v-model="request.dayMin"
      />
      <br /><label for="dayMax">Date de fin : </label>
      <input
        v-on:change="fillData()"
        class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
        type="date"
        name="dayMax"
        id="dayMax"
        v-model="request.dayMax"
      />
      <br /><label for="interval">Intervalle : </label>
      <select
        v-on:change="fillData()"
        class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
        name="interval"
        id="interval"
        v-model="request.interval"
      >
        <option value="DAY">Jour</option>
        <option value="WEEK">Semaine</option>
        <option value="MONTH">Mois</option>
      </select>
      <br /><label for="analysts">Analystes : </label>
      <multiselect
        class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
        v-model="requestedAnalysts"
        :options="analysts"
        :multiple="true"
        group-values="analysts"
        group-label="name"
        :group-select="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        label="lastname"
        track-by="lastname"
        :custom-label="customLabelAnalyst"
        :placeholder="'Sélectionner un ou des analystes'"
        @input="
          updateAnalysts();
          fillData();
        "
      >
        <template slot="beforeList">
          <li class="before-list multiselect__element">
            <span
              class="multiselect__option"
              @mouseover="hoverSelectNoneAnalyst = true"
              @mouseout="hoverSelectNoneAnalyst = false"
              :class="{
                'multiselect__option multiselect__option--highlight multiselect__option--selected': hoverSelectNoneAnalyst,
              }"
              @click="
                selectNoneAnalyst();
                fillData();
              "
              ><span>Aucun</span></span
            >
          </li>
          <li class="before-list multiselect__element">
            <span
              class="multiselect__option"
              @mouseover="hoverSelectAllAnalyst = true"
              @mouseout="hoverSelectAllAnalyst = false"
              :class="{
                'multiselect__option multiselect__option--highlight': hoverSelectAllAnalyst,
              }"
              @click="
                selectAllAnalysts();
                fillData();
              "
              ><span>Tous</span></span
            >
          </li>
        </template>
        <template slot="selection" slot-scope="{ values, isOpen }"
          ><span
            class="multiselect__single"
            v-if="values.length &amp;&amp; !isOpen"
            >{{ values.length }} analystes sélectionnés</span
          ></template
        >
      </multiselect>
      <label for="departments">Départments : </label>
      <multiselect
        class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
        v-model="requestedDepartments"
        :options="departments"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        label="name"
        track-by="name"
        :custom-label="customLabelDepartment"
        :placeholder="'Sélectionner un ou des départements'"
        @input="
          updateDepartments();
          fillData();
        "
      >
        <template slot="beforeList">
          <li class="before-list multiselect__element">
            <span
              class="multiselect__option"
              @mouseover="hoverSelectNoneDepartment = true"
              @mouseout="hoverSelectNoneDepartment = false"
              :class="{
                'multiselect__option multiselect__option--highlight multiselect__option--selected': hoverSelectNoneDepartment,
              }"
              @click="
                selectNoneDepartment();
                fillData();
              "
              ><span>Aucun</span></span
            >
          </li>
          <li class="before-list multiselect__element">
            <span
              class="multiselect__option"
              @mouseover="hoverSelectAllDepartment = true"
              @mouseout="hoverSelectAllDepartment = false"
              :class="{
                'multiselect__option multiselect__option--highlight': hoverSelectAllDepartment,
              }"
              @click="
                selectAllDepartments();
                fillData();
              "
              ><span>Tous</span></span
            >
          </li>
        </template>
        <template slot="selection" slot-scope="{ values, isOpen }"
          ><span
            class="multiselect__single"
            v-if="values.length &amp;&amp; !isOpen"
            >{{ values.length }} départements sélectionnés</span
          ></template
        >
      </multiselect>
    </form>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import AnalystService from "../../services/analyst.service";
import StatisticService from "../../services/statistic.service";
import BarChart from "./BarChart";
import Multiselect from "vue-multiselect";
import AgencyService from "../../services/agency.service";
import DepartmentService from "../../services/department.service";

Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

export default {
  components: {
    BarChart,
    Multiselect,
  },
  data() {
    return {
      statistics: [],
      chartData: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  if (Number.isInteger(value)) {
                    return value;
                  }
                },
                stepSize: 1,
              },
            },
          ],
        },
      },
      request: {
        // Par mois
        // dayMin: "2020-10-01",
        // Par jour
        dayMin: "2021-09-13",
        dayMax: "2021-09-19",
        interval: "DAY",
        analysts: [],
        departments: ["77", "36"],
      },
      analysts: [],
      requestedAnalysts: [],
      departments: [],
      requestedDepartments: [],
      hoverSelectNoneAnalyst: false,
      hoverSelectAllAnalyst: false,
      hoverSelectNoneDepartment: false,
      hoverSelectAllDepartment: false,
    };
  },
  mounted() {
    var curr = new Date();

    this.request.dayMin = this.getMonday(curr)
      .toISOString()
      .slice(0, 10);
    this.request.dayMax = new Date(
      curr.setDate(curr.getDate() - curr.getDay() + 7)
    )
      .toISOString()
      .slice(0, 10);

    AgencyService.getAgencies().then((response) => {
      this.analysts = response.data;

      AnalystService.getAnalysts().then((response) => {
        this.analysts.push({ name: "Analystes", analysts: response.data });
        this.requestedAnalysts = response.data;

        DepartmentService.getDepartments().then((response) => {
          this.departments = response.data;
          this.requestedDepartments = response.data;

          this.updateAnalysts();
          this.updateDepartments();
          this.fillData();
        });
      });
    });
  },
  methods: {
    fillData() {
      StatisticService.getCountByHosting(this.request).then((response) => {
        this.statistics = response.data;

        var owners = [];
        var tenants = [];
        var lodgers = [];
        var totals = [];

        if (this.request.interval == "MONTH") {
          var months = this.monthRange(
            this.request.dayMin,
            this.request.dayMax
          );

          months.forEach((month) => {
            var ownerFinded = false;
            var tenantFinded = false;
            var lodgerFinded = false;
            var total = 0;

            this.statistics.forEach((statistic) => {
              if (statistic.monthOfReceipt == month) {
                if (statistic.housingSituation == "Propriétaire") {
                  owners.push(statistic.count);
                  total += statistic.count;
                  ownerFinded = true;
                }
                if (statistic.housingSituation == "Locataire") {
                  tenants.push(statistic.count);
                  total += statistic.count;
                  tenantFinded = true;
                }
                if (statistic.housingSituation == "Hébergé") {
                  lodgers.push(statistic.count);
                  total += statistic.count;
                  lodgerFinded = true;
                }
              }
            });

            if (ownerFinded == false) owners.push(0);
            if (tenantFinded == false) tenants.push(0);
            if (lodgerFinded == false) lodgers.push(0);

            totals.push(total);
          });

          this.chartData = {
            labels: months,
            datasets: [
              {
                label: "Propriétaire",
                backgroundColor: "#EF4444",
                data: owners,
                order: 1,
              },
              {
                label: "Locataire",
                backgroundColor: "#F59E0B",
                data: tenants,
                order: 2,
              },
              {
                label: "Hébergé",
                backgroundColor: "#10B981",
                data: lodgers,
                order: 3,
              },
              {
                type: "line",
                label: "Total",
                borderColor: "#3B82F6",
                data: totals,
                order: 0,
              },
            ],
          };
        }

        if (this.request.interval == "WEEK") {
          var weeks = this.weekRange(this.request.dayMin, this.request.dayMax);

          weeks.forEach((week) => {
            var ownerFinded = false;
            var tenantFinded = false;
            var lodgerFinded = false;
            var total = 0;

            this.statistics.forEach((statistic) => {
              if (statistic.weekOfReceipt == week) {
                if (statistic.housingSituation == "Propriétaire") {
                  owners.push(statistic.count);
                  total += statistic.count;
                  ownerFinded = true;
                }
                if (statistic.housingSituation == "Locataire") {
                  tenants.push(statistic.count);
                  total += statistic.count;
                  tenantFinded = true;
                }
                if (statistic.housingSituation == "Hébergé") {
                  lodgers.push(statistic.count);
                  total += statistic.count;
                  lodgerFinded = true;
                }
              }
            });

            if (ownerFinded == false) owners.push(0);
            if (tenantFinded == false) tenants.push(0);
            if (lodgerFinded == false) lodgers.push(0);

            totals.push(total);
          });

          this.chartData = {
            labels: weeks,
            datasets: [
              {
                label: "Propriétaire",
                backgroundColor: "#EF4444",
                data: owners,
                order: 1,
              },
              {
                label: "Locataire",
                backgroundColor: "#F59E0B",
                data: tenants,
                order: 2,
              },
              {
                label: "Hébergé",
                backgroundColor: "#10B981",
                data: lodgers,
                order: 3,
              },
              {
                type: "line",
                label: "Total",
                borderColor: "#3B82F6",
                data: totals,
                order: 0,
              },
            ],
          };
        }

        if (this.request.interval == "DAY") {
          var days = this.dayRange(this.request.dayMin, this.request.dayMax);

          days.forEach((day) => {
            var ownerFinded = false;
            var tenantFinded = false;
            var lodgerFinded = false;
            var total = 0;

            this.statistics.forEach((statistic) => {
              if (statistic.dayOfReceipt == day) {
                if (statistic.housingSituation == "Propriétaire") {
                  owners.push(statistic.count);
                  total += statistic.count;
                  ownerFinded = true;
                }
                if (statistic.housingSituation == "Locataire") {
                  tenants.push(statistic.count);
                  total += statistic.count;
                  tenantFinded = true;
                }
                if (statistic.housingSituation == "Hébergé") {
                  lodgers.push(statistic.count);
                  total += statistic.count;
                  lodgerFinded = true;
                }
              }
            });

            if (ownerFinded == false) owners.push(0);
            if (tenantFinded == false) tenants.push(0);
            if (lodgerFinded == false) lodgers.push(0);

            totals.push(total);
          });

          this.chartData = {
            labels: days,
            datasets: [
              {
                label: "Propriétaire",
                backgroundColor: "#EF4444",
                data: owners,
                order: 1,
              },
              {
                label: "Locataire",
                backgroundColor: "#F59E0B",
                data: tenants,
                order: 2,
              },
              {
                label: "Hébergé",
                backgroundColor: "#10B981",
                data: lodgers,
                order: 3,
              },
              {
                type: "line",
                label: "Total",
                borderColor: "#3B82F6",
                data: totals,
                order: 0,
              },
            ],
          };
        }
      });
    },
    monthRange(dayMin, dayMax) {
      var start = dayMin.split("-");
      var end = dayMax.split("-");
      var startYear = parseInt(start[0]);
      var endYear = parseInt(end[0]);
      var months = [];

      for (var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (
          var j = startMon;
          j <= endMonth;
          j = j > 12 ? j % 12 || 11 : j + 1
        ) {
          var month = j + 1;
          var displayMonth = month < 10 ? "0" + month : month;
          months.push([displayMonth, i].join("-"));
        }
      }
      return months;
    },
    weekRange(dayMin, dayMax) {
      var start = dayMin.split("-");
      var end = dayMax.split("-");
      var startYear = parseInt(start[0]);
      var endYear = parseInt(end[0]);
      var weeks = [];

      for (var i = startYear; i <= endYear; i++) {
        var endWeek = i != endYear ? 52 : parseInt(new Date(end).getWeek()) - 1;
        var startWe =
          i === startYear ? parseInt(new Date(start).getWeek()) - 1 : 0;

        console.log("starWe: " + startWe);
        console.log("endWeek: " + endWeek);
        for (var j = startWe; j <= endWeek; j = j > 53 ? j % 53 || 52 : j + 1) {
          var week = j + 1;
          weeks.push(["S" + week, i].join("-"));
        }
      }
      return weeks;
    },
    dayRange(dayMin, dayMax) {
      const dateArray = [];
      let currentDate = new Date(dayMin);

      while (currentDate <= new Date(dayMax)) {
        var day = new Date(currentDate)
          .toISOString()
          .slice(0, 10)
          .split("-");
        dateArray.push(day[2] + "-" + day[1] + "-" + day[0]);
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      return dateArray;
    },
    updateAnalysts() {
      let analysts = [];

      this.requestedAnalysts.forEach((requestedAnalyst) => {
        analysts.push(requestedAnalyst._id);
      });

      this.request.analysts = analysts;
    },
    customLabelAnalyst({ firstname, lastname }) {
      return `${firstname} ${lastname}`;
    },
    selectNoneAnalyst() {
      this.requestedAnalysts = [];
      this.updateAnalysts();
    },
    selectAllAnalysts() {
      this.requestedAnalysts = [];
      this.requestedAnalysts = this.analysts.find(
        (o) => o.name == "Analystes"
      ).analysts;
      this.updateAnalysts();
    },
    updateDepartments() {
      let departments = [];

      this.requestedDepartments.forEach((requestedDepartment) => {
        departments.push(requestedDepartment.number);
      });

      this.request.departments = departments;
    },
    customLabelDepartment({ number, name }) {
      return `${number} - ${name}`;
    },
    selectNoneDepartment() {
      this.requestedDepartments = [];
      this.updateDepartments();
    },
    selectAllDepartments() {
      this.requestedDepartments = [];
      this.requestedDepartments = this.departments;
      this.updateDepartments();
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
  },
};
</script>

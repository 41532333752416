import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class AnalystService {
  getAnalysts() {
    return axios.get(apiUri + "/analyst", { headers: authHeader() });
  }

  getAnalyst(analystId) {
    return axios.get(apiUri + "/analyst/" + analystId, {
      headers: authHeader(),
    });
  }

  postAnalyst(analyst) {
    return axios.post(
      apiUri + "/analyst",
      {
        firstname: analyst.firstname,
        lastname: analyst.lastname,
        email: analyst.email,
        enabled: analyst.enabled,
        crmId: analyst.crmId,
      },
      { headers: authHeader() }
    );
  }

  putAnalyst(analyst) {
    return axios.put(
      apiUri + "/analyst",
      {
        id: analyst.id,
        firstname: analyst.firstname,
        lastname: analyst.lastname,
        email: analyst.email,
        enabled: analyst.enabled,
        crmId: analyst.crmId,
      },
      { headers: authHeader() }
    );
  }

  deleteAnalyst(analystId) {
    return axios.delete(apiUri + "/analyst/" + analystId, {
      headers: authHeader(),
    });
  }
}

export default new AnalystService();

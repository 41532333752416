<template>
  <div class="dashboard">
    <!-- <div class="annual-chart-container">
      <h2 class="text-2xl font-bold mb-4">
        Statistiques par type d'hébergement sur les 12 derniers mois
      </h2>
      <annual-chart />
    </div> -->
    <hosting-chart />
  </div>
</template>

<script>
// import AnnualChart from "@/components/dashboard/AnnualChart.vue";
import HostingChart from "@/components/dashboard/HostingChart.vue";

export default {
  name: "Dashboard",
  components: {
    // AnnualChart,
    HostingChart,
  },
  metaInfo() {
    return {
      title: "Statistiques - Répartiteur PROJEFI",
    };
  },
};
</script>

<template>
  <div id="form">
    <router-view />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

library.add(faPlus);
library.add(faEdit);
library.add(faTrash);

export default {
  name: "Form",
};
</script>

import axios from "axios";
import { apiUri } from "../components/ApiConfig";

class authService {
  login(user) {
    return axios
      .post(apiUri + "/auth/login", {
        email: user.email,
        password: user.password,
      })
      .then((res) => {
        if (res.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }

        return res.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new authService();

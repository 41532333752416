import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class DepartmentService {
  getDepartments() {
    return axios.get(apiUri + "/department", { headers: authHeader() });
  }

  getDepartment(departmentId) {
    return axios.get(apiUri + "/department/" + departmentId, {
      headers: authHeader(),
    });
  }

  postDepartment(department) {
    return axios.post(
      apiUri + "/department",
      {
        name: department.name,
        number: department.number,
      },
      { headers: authHeader() }
    );
  }

  putDepartment(department) {
    return axios.put(
      apiUri + "/department",
      {
        id: department.id,
        name: department.name,
        number: department.number,
      },
      { headers: authHeader() }
    );
  }

  deleteDepartment(departmentId) {
    return axios.delete(apiUri + "/department/" + departmentId, {
      headers: authHeader(),
    });
  }
}

export default new DepartmentService();

<template>
  <div class="agency-container">
    <h2 class="text-2xl font-bold mb-4">
      Agences
      <button
        class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
        @click="addAgency()"
      >
        <font-awesome-icon icon="plus" />
      </button>
    </h2>

    <p
      v-if="error"
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200 mb-4"
    >
      {{ error.message }}
    </p>

    <table v-if="agencies.length > 0" class="table-fixed w-full text-center">
      <thead>
        <tr>
          <!-- <th class="w-2/12 bg-gray-100 border px-2 py-1">ID</th> -->
          <th class="w-3/8 bg-gray-100 border px-2 py-1">Nom</th>
          <th class="w-3/8 bg-gray-100 border px-2 py-1">Analystes</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Modifier</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Supprimer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="agency in agencies" :key="agency._id">
          <!-- <td class="border px-2 py-1">{{ agency._id }}</td> -->
          <td class="border px-2 py-1">{{ agency.name }}</td>
          <td class="border px-2 py-1">
            <ul>
              <li v-for="analyst in agency.analysts" :key="analyst._id">
                {{ analyst.firstname }} {{ analyst.lastname }}
              </li>
            </ul>
          </td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
              @click="updateAgency(agency._id)"
            >
              <font-awesome-icon icon="edit" />
            </button>
          </td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-red-500 hover:bg-red-700 text-white px-2 py-1 rounded"
              @click="deleteAgency(agency)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <p
      v-else
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200"
    >
      Aucune agence n'a été retrouvé.
    </p>
  </div>
</template>

<script>
import router from "../../router";

import AgencyService from "../../services/agency.service";

export default {
  name: "AgencyList",
  data: () => {
    return {
      agencies: null,
      error: null,
    };
  },
  metaInfo() {
    return {
      title: "Agences - Répartiteur PROJEFI",
    };
  },
  mounted() {
    AgencyService.getAgencies().then(
      (response) => {
        this.agencies = response.data;
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    addAgency: () => {
      router.push("/agency/add");
    },
    updateAgency: (agencyId) => {
      router.push("/agency/edit/" + agencyId);
    },
    deleteAgency: (agency) => {
      if (
        confirm(
          "Êtes-vous sûr de vouloir supprimer l'agence " + agency.name + " ?"
        )
      ) {
        AgencyService.deleteAgency(agency._id).then(
          (response) => {
            alert(response.data.message);
            document.location.reload();
          },
          (error) => {
            this.error =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

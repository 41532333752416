import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class FormService {
  getForms() {
    return axios.get(apiUri + "/form", { headers: authHeader() });
  }

  getForm(formId) {
    return axios.get(apiUri + "/form/" + formId, { headers: authHeader() });
  }

  postForm(form) {
    return axios.post(
      apiUri + "/form",
      { name: form.name, password: form.password },
      { headers: authHeader() }
    );
  }

  putForm(form) {
    return axios.put(
      apiUri + "/form",
      {
        id: form.id,
        name: form.name,
        password: form.password,
      },
      { headers: authHeader() }
    );
  }

  deleteForm(formId) {
    return axios.delete(apiUri + "/form/" + formId, { headers: authHeader() });
  }
}

export default new FormService();

import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class TypeOfAmountService {
  getTypeOfAmounts() {
    return axios.get(apiUri + "/typeOfAmount", { headers: authHeader() });
  }

  getTypeOfAmount(typeOfAmountId) {
    return axios.get(apiUri + "/typeOfAmount/" + typeOfAmountId, {
      headers: authHeader(),
    });
  }

  postTypeOfAmount(typeOfAmount) {
    return axios.post(
      apiUri + "/typeOfAmount",
      {
        name: typeOfAmount.name,
        amountMin: typeOfAmount.amountMin,
        amountMax: typeOfAmount.amountMax,
      },
      { headers: authHeader() }
    );
  }

  putTypeOfAmount(typeOfAmount) {
    return axios.put(
      apiUri + "/typeOfAmount",
      {
        id: typeOfAmount.id,
        name: typeOfAmount.name,
        amountMin: typeOfAmount.amountMin,
        amountMax: typeOfAmount.amountMax,
      },
      { headers: authHeader() }
    );
  }

  deleteTypeOfAmount(typeOfAmountId) {
    return axios.delete(apiUri + "/typeOfAmount/" + typeOfAmountId, {
      headers: authHeader(),
    });
  }
}

export default new TypeOfAmountService();

import axios from "axios";
import authHeader from "./auth-header";

import { apiUri } from "../components/ApiConfig";

class ProfileService {
  getProfiles() {
    return axios.get(apiUri + "/profile", { headers: authHeader() });
  }

  getProfile(profileId) {
    return axios.get(apiUri + "/profile/" + profileId, {
      headers: authHeader(),
    });
  }

  postProfile(profile) {
    return axios.post(
      apiUri + "/profile",
      {
        name: profile.name,
        bankOfFranceFile: profile.bankOfFranceFile,
        typeOfRequest: profile.typeOfRequest,
        counter: profile.enabled,
        departments: profile.departments,
        amountOfConsumerLoans: profile.amountOfConsumerLoans.id,
        amountOfPropertyLoans: profile.amountOfPropertyLoans.id,
        amountOfNeed: profile.amountOfNeed.id,
        typesOfHosting: profile.typesOfHosting,
        primaryAnalysts: profile.primaryAnalysts,
        secondaryAnalysts: profile.secondaryAnalysts,
      },
      { headers: authHeader() }
    );
  }

  putProfile(profile) {
    return axios.put(
      apiUri + "/profile",
      {
        id: profile.id,
        name: profile.name,
        bankOfFranceFile: profile.bankOfFranceFile,
        typeOfRequest: profile.typeOfRequest,
        counter: profile.enabled,
        departments: profile.departments,
        amountOfConsumerLoans: profile.amountOfConsumerLoans.id,
        amountOfPropertyLoans: profile.amountOfPropertyLoans.id,
        amountOfNeed: profile.amountOfNeed.id,
        typesOfHosting: profile.typesOfHosting,
        primaryAnalysts: profile.primaryAnalysts,
        secondaryAnalysts: profile.secondaryAnalysts,
      },
      { headers: authHeader() }
    );
  }

  deleteProfile(profileId) {
    return axios.delete(apiUri + "/profile/" + profileId, {
      headers: authHeader(),
    });
  }
}

export default new ProfileService();

<template>
  <div class="profile-container">
    <h2 class="text-2xl font-bold mb-4">
      Profiles
      <button
        class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
        @click="addProfile()"
      >
        <font-awesome-icon icon="plus" />
      </button>
    </h2>

    <p
      v-if="error"
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200 mb-4"
    >
      {{ error.message }}
    </p>

    <table v-if="profiles.length > 0" class="table-fixed w-full text-center">
      <thead>
        <tr>
          <!-- <th class="bg-gray-100 border px-2 py-1">ID</th> -->
          <th class="bg-gray-100 border px-2 py-1">Nom</th>
          <th class="bg-gray-100 border px-2 py-1">
            Fiché banque de France
          </th>
          <th class="bg-gray-100 border px-2 py-1">Type de demande</th>
          <th class="bg-gray-100 border px-2 py-1">Départements</th>
          <th class="bg-gray-100 border px-2 py-1">Montant conso</th>
          <th class="bg-gray-100 border px-2 py-1">Montant immo</th>
          <th class="bg-gray-100 border px-2 py-1">Montant besoin</th>
          <th class="bg-gray-100 border px-2 py-1">Hébergement</th>
          <th class="bg-gray-100 border px-2 py-1">
            Analystes primaires
          </th>
          <th class="bg-gray-100 border px-2 py-1">
            Analystes secondaires
          </th>
          <th class="bg-gray-100 border px-2 py-1">Modifier</th>
          <th class="bg-gray-100 border px-2 py-1">Supprimer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="profile in profiles" :key="profile._id">
          <!-- <td class="border px-2 py-1">{{ profile._id }}</td> -->
          <td class="border px-2 py-1">{{ profile.name }}</td>
          <td class="border px-2 py-1">
            <!-- {{ profile.bankOfFranceFile }} -->
            <font-awesome-icon
              v-if="profile.bankOfFranceFile == true"
              icon="check"
              class="text-green-500"
            /><font-awesome-icon
              v-if="profile.bankOfFranceFile == false"
              icon="times"
              class="text-red-500"
            />
          </td>
          <td class="border px-2 py-1">{{ profile.typeOfRequest }}</td>
          <td class="border px-2 py-1">
            <p>
              <span
                v-for="(department, key) in profile.departments"
                :key="department._id"
              >
                <span v-if="key !== 0">, </span>
                {{ department.number }}
              </span>
            </p>
          </td>
          <td class="border px-2 py-1">
            {{ profile.amountOfConsumerLoans.amountMin }} € -
            {{ profile.amountOfConsumerLoans.amountMax }} €
          </td>
          <td class="border px-2 py-1">
            {{ profile.amountOfPropertyLoans.amountMin }} € -
            {{ profile.amountOfPropertyLoans.amountMax }} €
          </td>
          <td class="border px-2 py-1">
            {{ profile.amountOfNeed.amountMin }} € -
            {{ profile.amountOfNeed.amountMax }} €
          </td>
          <td class="border px-2 py-1">
            <ul>
              <li
                v-for="typeOfHosting in profile.typesOfHosting"
                :key="typeOfHosting._id"
              >
                {{ typeOfHosting.name }}
              </li>
            </ul>
          </td>
          <td class="border px-2 py-1">
            <ul>
              <li
                v-for="primaryAnalyst in profile.primaryAnalysts"
                :key="primaryAnalyst._id"
              >
                {{ primaryAnalyst.firstname }} {{ primaryAnalyst.lastname }}
              </li>
            </ul>
          </td>
          <td class="border px-2 py-1">
            <ul>
              <li
                v-for="secondaryAnalyst in profile.secondaryAnalysts"
                :key="secondaryAnalyst._id"
              >
                {{ secondaryAnalyst.firstname }} {{ secondaryAnalyst.lastname }}
              </li>
            </ul>
          </td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
              @click="updateProfile(profile._id)"
            >
              <font-awesome-icon icon="edit" />
            </button>
          </td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-red-500 hover:bg-red-700 text-white px-2 py-1 rounded"
              @click="deleteProfile(profile)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <p
      v-else
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200"
    >
      Aucun profile n'a été retrouvé.
    </p>
  </div>
</template>

<script>
import router from "../../router";

import ProfileService from "../../services/profile.service";
import DepartmentService from "../../services/department.service";

export default {
  name: "ProfileList",
  data: () => {
    return {
      profiles: null,
      error: null,
    };
  },
  metaInfo() {
    return {
      title: "Profiles - Répartiteur PROJEFI",
    };
  },
  mounted() {
    ProfileService.getProfiles().then(
      (response) => {
        this.profiles = response.data;
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    addProfile: () => {
      router.push("/profile/add");
    },
    updateProfile: (profileId) => {
      router.push("/profile/edit/" + profileId);
    },
    deleteProfile: (profile) => {
      if (
        confirm(
          "Êtes-vous sûr de vouloir supprimer le profile " + profile.name + " ?"
        )
      ) {
        ProfileService.deleteProfile(profile._id).then(
          (response) => {
            alert(response.data.message);
            document.location.reload();
          },
          (error) => {
            this.error =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

<template>
  <div id="app" class="flex">
    <nav
      class="bg-gray-800 w-1/12 h-screen sticky top-0"
      v-if="$route.path != '/login'"
    >
      <div class="flex flex-col items-center space-y-2 px-4 py-4 h-full">
        <img src="./assets/logo.png" alt="Logo PROJEFI" class="w-4/5 my-2" />
        <p class="text-white text-l font-bold">
          DISPATCHER
        </p>
        <p class="text-gray-500 text-xs font-light">
          v1.5.0
        </p>
        <router-link
          class="px-4 py-2 rounded-md text-sm font-medium w-full text-center"
          v-bind:class="[homeStyle]"
          to="/"
          >Statistiques</router-link
        >
        <router-link
          class="px-4 py-2 rounded-md text-sm font-medium w-full text-center"
          v-bind:class="[formStyle]"
          to="/form"
          >Formulaires</router-link
        >
        <router-link
          class="px-4 py-2 rounded-md text-sm font-medium w-full text-center"
          v-bind:class="[analystStyle]"
          to="/analyst"
          >Analystes</router-link
        >
        <router-link
          class="px-4 py-2 rounded-md text-sm font-medium w-full text-center"
          v-bind:class="[agencyStyle]"
          to="/agency"
          >Agences</router-link
        >
        <router-link
          class="px-4 py-2 rounded-md text-sm font-medium w-full text-center"
          v-bind:class="[profileStyle]"
          to="/profile"
          >Profiles</router-link
        >
        <button
          @click="logout()"
          class="px-4 py-2 rounded-md text-sm font-medium w-full text-center text-white bg-red-700 hover:bg-red-300"
        >
          <font-awesome-icon icon="sign-out-alt" />
        </button>
      </div>
    </nav>

    <router-view class="container mx-auto my-8" />
  </div>
</template>

<style>
@import "./assets/style/tailwind.css";

.nav-active {
  @apply bg-gray-900;
  @apply text-white;
}

.nav-inactive {
  @apply text-gray-300;
}

.nav-inactive:hover {
  @apply bg-gray-700;
  @apply text-white;
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faSignOutAlt);

export default {
  name: "App",
  data: () => {
    return {
      homeStyle: null,
      formStyle: null,
      analystStyle: null,
      agencyStyle: null,
      profileStyle: null,
    };
  },
  mounted() {
    switch (this.$route.name) {
      case "Dashboard":
        this.homeStyle = "nav-active";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "FormList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-active";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "FormAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-active";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "AnalystList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-active";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "AnalystAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-active";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "AgencyList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-active";
        this.profileStyle = "nav-inactive";
        break;

      case "AgencyAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-active";
        this.profileStyle = "nav-inactive";
        break;

      case "ProfileList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-active";
        break;

      case "ProfileAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-active";
        break;
    }
  },
  updated() {
    switch (this.$route.name) {
      case "Dashboard":
        this.homeStyle = "nav-active";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "FormList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-active";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "FormAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-active";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "AnalystList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-active";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "AnalystAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-active";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-inactive";
        break;

      case "AgencyList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-active";
        this.profileStyle = "nav-inactive";
        break;

      case "AgencyAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-active";
        this.profileStyle = "nav-inactive";
        break;

      case "ProfileList":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-active";
        break;

      case "ProfileAdd":
        this.homeStyle = "nav-inactive";
        this.formStyle = "nav-inactive";
        this.analystStyle = "nav-inactive";
        this.agencyStyle = "nav-inactive";
        this.profileStyle = "nav-active";
        break;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

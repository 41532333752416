<template>
  <div class="form-container">
    <h2 class="text-2xl font-bold mb-4">
      Formulaires
      <button
        class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
        @click="addForm()"
      >
        <font-awesome-icon icon="plus" />
      </button>
    </h2>

    <p
      v-if="error"
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200 mb-4"
    >
      {{ error.message }}
    </p>

    <table v-if="forms.length > 0" class="table-fixed w-full text-center">
      <thead>
        <tr>
          <!-- <th class="w-2/12 bg-gray-100 border px-2 py-1">ID</th> -->
          <th class="w-3/8 bg-gray-100 border px-2 py-1">Nom</th>
          <th class="w-3/8 bg-gray-100 border px-2 py-1">Mot de passe</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Modifier</th>
          <th class="w-1/8 bg-gray-100 border px-2 py-1">Supprimer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="form in forms" :key="form._id">
          <!-- <td class="border px-2 py-1">{{ form._id }}</td> -->
          <td class="border px-2 py-1">{{ form.name }}</td>
          <td class="border px-2 py-1">************</td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
              @click="updateForm(form._id)"
            >
              <font-awesome-icon icon="edit" />
            </button>
          </td>
          <td class="border px-2 py-1 text-center">
            <button
              class="bg-red-500 hover:bg-red-700 text-white px-2 py-1 rounded"
              @click="deleteForm(form)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <p
      v-else
      class="px-2 py-1 rounded border border-red-900 text-red-900 bg-red-200"
    >
      Aucun formulaire n'a été retrouvé.
    </p>
  </div>
</template>

<script>
import router from "../../router";

import FormService from "../../services/form.service";

export default {
  name: "FormList",
  data: () => {
    return {
      forms: null,
      error: null,
    };
  },
  metaInfo() {
    return {
      title: "Formulaires - Répartiteur PROJEFI",
    };
  },
  mounted() {
    FormService.getForms().then(
      (response) => {
        this.forms = response.data;
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    addForm: () => {
      router.push("/form/add");
    },
    updateForm: (formId) => {
      router.push("/form/edit/" + formId);
    },
    deleteForm: (form) => {
      if (
        confirm(
          "Êtes-vous sûr de vouloir supprimer le formulaire " + form.name + " ?"
        )
      ) {
        FormService.deleteForm(form._id).then(
          (response) => {
            alert(response.data.message);
            document.location.reload();
          },
          (error) => {
            this.error =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

<template>
  <div class="agency-container">
    <h2 class="text-2xl font-bold mb-4" v-if="edit == false">
      Ajout d'une agence
    </h2>
    <h2 class="text-2xl font-bold mb-4" v-if="edit == true">
      Modification d'une agence
    </h2>

    <label for="name">Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="agency.name"
      type="text"
      name="name"
      id="name"
    />
    <br />

    <label for="analysts">Analystes :</label>
    <ul>
      <li v-for="analyst in analysts" :key="analyst._id">
        <input
          class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
          v-model="agency.analysts"
          type="checkbox"
          :name="analyst._id"
          :id="analyst._id"
          :value="analyst._id"
        />
        {{ analyst.crmId }} - {{ analyst.firstname }} {{ analyst.lastname }}
      </li>
    </ul>
    <br />

    <button
      class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
      v-if="edit == false"
      @click="addAgency(agency)"
    >
      Créer
    </button>
    <button
      class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
      v-if="edit == true"
      @click="updateAgency(agency)"
    >
      Modifier
    </button>
  </div>
</template>

<script>
import router from "../../router";

import AgencyService from "../../services/agency.service";
import AnalystService from "../../services/analyst.service";

export default {
  name: "AgencyAdd",
  data: () => {
    return {
      agency: {
        id: null,
        name: null,
        analysts: [],
      },
      analysts: [],
      edit: null,
      error: null,
      title: " une agence - Répartiteur PROJEFI",
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  mounted() {
    if (this.$route.path == "/agency/add") {
      this.edit = false;
      this.title = "Ajouter" + this.title;
    } else {
      this.edit = true;
      this.title = "Modifier" + this.title;
      this.agency.id = this.$route.params.id;

      AgencyService.getAgency(this.agency.id).then(
        (response) => {
          if (response.data.message) {
            alert(response.data.message);
            return;
          } else {
            this.agency.name = response.data.name;
            this.agency.analysts = response.data.analysts;
          }
        },
        (error) => {
          this.error =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }

    AnalystService.getAnalysts().then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
          return;
        } else {
          this.analysts = response.data;
        }
      },
      (error) => {
        this.error =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    addAgency: (agency) => {
      if (agency.name == null || agency.name == "") {
        alert("Le nom d'agence ne doit pas être laissé vide.");
        return;
      }

      AgencyService.postAgency(agency).then((response) => {
        alert(response.data.message);
        router.push("/agency/list");
      });
    },
    updateAgency: (agency) => {
      if (confirm("Êtes-vous sûr de vouloir modifier l'agence ?")) {
        if (agency.name == null || agency.name == "") {
          alert("Le nom d'agence ne doit pas être laissé vide.");
          return;
        }

        AgencyService.putAgency(agency).then((response) => {
          alert(response.data.message);
          router.push("/agency/list");
        });
      }
    },
  },
};
</script>

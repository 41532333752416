<template>
  <div class="form-container">
    <h2 class="text-2xl font-bold mb-4" v-if="edit == false">
      Ajout d'un formulaire
    </h2>
    <h2 class="text-2xl font-bold mb-4" v-if="edit == true">
      Modification d'un formulaire
    </h2>

    <label for="name">Nom :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="form.name"
      type="text"
      name="name"
      id="name"
    />
    <br />

    <label for="password">Mot de passe :</label>
    <input
      class="px-2 py-1 rounded text-sm border border-gray-400 outline-none focus:border-green-500 mb-2 ml-2"
      v-model="form.password"
      type="password"
      name="password"
      id="password"
    />
    <br />

    <button
      class="bg-green-500 hover:bg-green-700 text-white px-2 py-1 rounded"
      v-if="edit == false"
      @click="addForm(form)"
    >
      Créer
    </button>
    <button
      class="bg-yellow-500 hover:bg-yellow-700 text-white px-2 py-1 rounded"
      v-if="edit == true"
      @click="updateForm(form)"
    >
      Modifier
    </button>
  </div>
</template>

<script>
import router from "../../router";

import FormService from "../../services/form.service";

export default {
  name: "FormAdd",
  data: () => {
    return {
      form: {
        id: null,
        name: null,
        password: null,
      },
      edit: null,
      error: null,
      title: " un formulaire - Répartiteur PROJEFI",
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  mounted() {
    if (this.$route.path == "/form/add") {
      this.edit = false;
      this.title = "Ajouter" + this.title;
    } else {
      this.edit = true;
      this.title = "Modifier" + this.title;
      this.form.id = this.$route.params.id;

      FormService.getForm(this.form.id).then(
        (response) => {
          if (response.data.message) {
            alert(response.data.message);
            return;
          } else {
            this.form.name = response.data.name;
            this.form.password = response.data.password;
          }
        },
        (error) => {
          this.error =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  methods: {
    addForm: (form) => {
      if (
        form.name == null ||
        form.name == "" ||
        form.password == null ||
        form.password == ""
      ) {
        alert("Aucun champ ne doit être laissé vide.");
        return;
      }

      FormService.postForm(form).then((response) => {
        alert(response.data.message);
        router.push("/form/list");
      });
    },
    updateForm: (form) => {
      if (confirm("Êtes-vous sûr de vouloir modifier le formulaire ?")) {
        if (
          form.name == null ||
          form.name == "" ||
          form.password == null ||
          form.password == ""
        ) {
          alert("Aucun champ ne doit être laissé vide.");
          return;
        }

        FormService.putForm(form).then((response) => {
          alert(response.data.message);
          router.push("/form/list");
        });
      }
    },
  },
};
</script>
